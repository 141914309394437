

export default function WarningDelay({start, duration})
{
  const delay = Math.floor((new Date(start) - new Date()) / (1000 * 60 * 60 * 24)) + 1;
  return (
    Math.min(Math.ceil(duration), 4) > delay / 7 &&
    <div className={"text-orange-500 bg-orange-50 border border-orange-500 rounded-lg px-3 py-2 w-full"}>
      Ce congé ne respecte pas le délai de prévenance de {Math.min(Math.ceil(duration), 4)} semaine(s)
    </div>
  )
}