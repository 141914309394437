import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getLeave, respondLeave, cancelLeave} from "../../Services/leave";
import SideBar from "../../Layouts/SideBar";
import Loading from "../../Utils/Loading";
import Card from "../../Utils/Card";
import Icon from "../../Utils/Icon";
import {DateRange} from "react-date-range";
import {fr} from "date-fns/locale";
import {getPublicHolidays} from "../../Services/publicHolidays";
import {ConfirmButton} from "../../Utils/Button";
import useMe from "../../Hooks/useMe";
import {getGroup} from "../../Services/group";
import WarningDelay from "../../Components/WarningDelay";
import ApproveModal from "../../Components/LeavesList/ApproveModal";
import {TextArea} from "../../Utils/InputText";

const statusColors = {
  "approved": "bg-green-600",
  "refused": "text-white bg-red-600",
  "ignored": "text-white bg-orange-600",
  "canceled": "text-white bg-gray-600",
  "pending": "bg-indigo-600"
}
const statusIcons = {
  "approved": "check",
  "refused": "remove",
  "ignored": "cancel",
  "canceled": "cancel",
  "pending": "schedule"
}
const statusText = {
  "approved": "Accepté",
  "refused": "Refusé",
  "ignored": "Ignoré",
  "canceled": "Annulé",
  "pending": "En attente"
}

function DisabledDate(props) {
  const {leave, holidays} = props;
  const endDate = new Date(leave.end);
  const [selectionRange] = useState({
    startDate: new Date(leave.start),
    endDate: endDate,
    key: 'selection',
    color: '#6366f1',
  });

  return (
    <div className={"flex flex-col gap-1"}>
      <h1
        className={"text-lg font-bold"}>
        Du {new Date(leave.start).toLocaleDateString("fr-FR")} au {endDate.toLocaleDateString("fr-FR")}
      </h1>
      <h2
        className={"text-md font-medium text-gray-500"}>
        Du {leave.halfDayStart ? "midi" : 'matin'} au {leave.halfDayEnd ? "midi" : 'soir'}
      </h2>
      <div className={"relative flex flex-col items-center"}>
        <DateRange ranges={[selectionRange]}
                   disabled={true}
                   selectRange={false}
                   showDateDisplay={false}
                   minDate={new Date()}
                   autofocus={false}
                   maxDate={new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)}
                   disabledDay={(date) => {
                     if (date.getDay() === 0 || date.getDay() === 6)
                       return true;
                     for (const holiday of holidays) {
                       if (holiday.date.getDate() === date.getDate() && holiday.date.getMonth() === date.getMonth()) {
                         if (holiday.repeat || holiday.date.getFullYear() === date.getFullYear())
                           return true;
                       }
                     }
                     return false;
                   }}
                   locale={fr}
        />
        <div className={"absolute bottom-0 top-16 left-0 w-full bg-gray-100 opacity-40 z-10"}
             onClick={(e) => e.preventDefault()}/>
      </div>
      <p className={"text-sm text-gray-500"}>Pour un total de {leave.duration} jours</p>
    </div>
  )
}

function canApprove(leave, me, group) {
  if (me.roles.includes("ROLE_ADMIN"))
    return true;
  if (!group)
    return false;
  const manager = group.managers.find((manager) => manager.user.id === me.id);
  if (manager && manager.super)
    return true;
  return (manager && !group.managers.find((manager) => manager.user.id === leave.requester.id));
}

export default function Leave() {
  const {id} = useParams();
  const [holidays, setHolidays] = useState([]);
  const [group, setGroup] = useState(null);
  const [leave, setLeave] = useState(null);
  const [error, setError] = useState(null);
  const [comment, setComment] = useState("");
  const me = useMe();

  function cancel() {
    cancelLeave(leave.id, comment).then(() => {
      window.location.reload();
    });
  }

  useEffect(() => {
    if (id) {
      getLeave(id).then((json) => {
        if (json) {
          setLeave(json);
          if (json.requester.inGroup)
            getGroup(json.requester.inGroup.id).then((json) => {
              setGroup(json);
            });
        } else {
          setError("La demande de congé n'existe pas ou a été supprimée.");
        }
      });
    }
    getPublicHolidays().then((json) => {
      if (!json)
        return;
      json = json.map((holiday) => {
        return {...holiday, date: new Date(holiday.date)};
      });
      setHolidays(json);
    });
  }, []);

  function confirm(approve) {
    respondLeave(leave.id, approve, comment).then((json) => {
      if (!json || json.error) {
        setError("Erreur lors de la confirmation");
      } else if (json.message) {
        setError(json.message);
      } else {
        setError("");
      }
      window.location.reload();
    });
  }

  if (error) {
    return (
      <SideBar>
        <div className={"flex w-full h-full flex-col items-center justify-center gap-6 p-8"}>
          <h1 className={"lg:text-2xl text-lg font-bold text-gray-700"}>{error}</h1>
          <p className={"text-gray-500"}>Veillez à vérifier l'URL ou contactez la personne concernée pour avoir plus d'information</p>
        </div>
      </SideBar>
    )
  }

  return (
    <SideBar>
      {leave === null ? <Loading page/> :
        <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700 overflow-x-hidden"}>
          <div className={"flex w-full gap-4 justify-between pl-18 md:pl-0"}>
            <Card className={"transi lg:px-5 lg:py-3 px-3 py-2 flex items-center"}>
              <h1 className={"lg:text-2xl text-lg font-bold text-gray-700"}>Congé
                du {new Date(leave.start).toLocaleDateString("fr-FR")} au {new Date(leave.end).toLocaleDateString("fr-FR")} </h1>
            </Card>
          </div>
          <Card className={"flex items-start flex-col justify-center p-6 gap-4"}>
            <div className={"flex items-start lg:flex-row flex-col justify-center gap-4"}>
              <div className={"flex flex-col gap-2 w-1/2 min-w-[350px]"}>
                <h1 className={"text-lg font-bold flex items-center pb-2"}>
                  <Icon name={statusIcons[leave.status]}
                        className={"text-3xl p-1 rounded-lg text-white mr-2 " + statusColors[leave.status]}/>
                  De {leave.requester.fname} {leave.requester.lname}
                </h1>
                <h2 className={"text-sm text-gray-700"}>
                <span className={"hidden md:inline"}>
                  Demandé le </span>{new Date(leave.dateRequest).toLocaleDateString("fr-FR")}
                </h2>
                <h1 className={"text-lg font-bold"}>Statut: {statusText[leave.status]}</h1>
                <h1 className={"text-lg font-bold"}>Service {group ? group.name : "Auncun"}</h1>
                <h1 className={"text-lg font-bold"}>Type {leave.type.name}</h1>
                <p className={"text-sm text-gray-500"}>{leave.type.description}</p>
                <h1 className={"text-lg font-bold"}>Commentaire:</h1>
                <p className={"text-sm text-gray-500"}>{leave.comment}</p>
              </div>
              <div className={"flex flex-col gap-2 w-1/2 min-w-[350px]"}>
                <DisabledDate leave={leave} holidays={holidays}/>
              </div>
            </div>
            <WarningDelay duration={leave.duration} start={leave.start}/>
            <div className={"flex w-full justify-between gap-2 mt-2"}>
              {(leave.status === "pending" && me.id === leave.requester.id)
              || (leave.status === "approved" && canApprove(leave, me, group)) ? (<>
                <ConfirmButton title={"Êtes vous sûr de vouloir annuler la demande ?"}
                              message={
                                <div className="flex flex-col gap-4">
                                  <p className={"text-gray-500"}>Vous ne pourrez pas revenir en arrière.</p>
                                  <label htmlFor={"motif"} className={"text-black font-medium text-sm"}>Motif d'annulation:</label>
                                  <TextArea id={"motif"} placeholder={"Commentaire"} className={"h-24"}
                                            onChange={(e) => setComment(e.target.value)}
                                  />
                                </div>
                              } className={"md:w-40 h-12"} color={"red"} variant={"outline"} onClick={cancel}>
                                Annuler
                  </ConfirmButton>
              </>) : (<div/>)}
              {canApprove(leave, me, group) && leave.status === "pending" && (<div className={"flex justify-end gap-2"}>
                  <ConfirmButton title={"Êtes vous sûr de vouloir refuser la demande ?"}
                                 className={"md:w-40 h-12"} color={"red"} onClick={() => confirm(false)}
                                 message={(
                                   <div className={"flex flex-col gap-4 w-full"}>
                                     <TextArea id={"motif"} placeholder={"Commentaire"} className={"h-24"} onChange={(e) => setComment(e.target.value)}>
                                       {comment}
                                     </TextArea>
                                     <WarningDelay duration={leave.duration} start={leave.start}/>
                                     <span className={"text-red-500 text-center"}>Attention, cette action est irréversible</span>
                                   </div>
                                 )}
                  >
                    Refuser
                  </ConfirmButton>
                  <ConfirmButton title={"Êtes vous sûr de vouloir valider la demande ?"}
                                 message={
                                   <div className={"flex flex-col gap-4 w-full"}>
                                     <WarningDelay duration={leave.duration} start={leave.start}/>
                                     <span className={"text-red-500 text-center"}>Attention, cette action est irréversible</span>
                                   </div>
                                 }
                                 className={"md:w-40 h-12"} color={"green"} onClick={() => confirm(true)}
                  >Accepter</ConfirmButton>
                </div>
              )}
            </div>
          </Card>
        </div>}
    </SideBar>
  )
}
