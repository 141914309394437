import {Route, BrowserRouter as Router, Routes} from "react-router-dom";
import './App.css';
import Login from "./Pages/Login";
import CreateGroup from "./Pages/Group/CreateGroup";
import ListGroup from "./Pages/Group/Groups";
import LeaveTypes from "./Pages/Leave/Type/LeaveTypes";
import {refreshToken} from "./Services/login";
import Users from "./Pages/User/Users";
import CreateUser from "./Pages/User/CreateUser";
import CreateLeaveType from "./Pages/Leave/Type/CreateLeaveType";
import useToken from "./Hooks/useToken";
import LeaveRequest from "./Pages/Leave/Request";
import LeaveResponse from "./Pages/Leave/Response";
import HistoryResponse from "./Pages/Leave/HistoryResponse";
import Profile from "./Pages/Profile";
import UserProfile from "./Pages/User/Profile";
import AllLeaveResponse from "./Pages/Leave/AllLeaveResponse";
import Group from "./Pages/Group/Group";
import GroupSelect from "./Pages/Group/GroupSelect";
import CreatePublicHolidays from "./Pages/PublicHolidays/CreatePublicHolidays";
import PublicHolidays from "./Pages/PublicHolidays/PublicHolidays";
import ConfigPage from "./Pages/Config";
import FourOFour from "./Pages/404";
import Leave from "./Pages/Leave/Leave";
import {useState} from "react";
import LoginForgot from "./Pages/LoginForgot";
import EditLeave from "Pages/Leave/EditLeave";

function App() {
  const {setToken} = useToken();
  const [login, setLogin] = useState(true);

  if (!window.location.pathname.startsWith("/login") && login)
    refreshToken().then((json) => {
      if (!json) {
        setLogin(false);
        return;
      }
      setToken(json.token);
    })

  if (!login) {
    return <Login reload/>
  }
  if (window.location.pathname === "/")
    window.location.href = "/profile";

  return (
    <>
      <Router>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="login/forgot/:token" element={<LoginForgot />} />
          <Route path="group">
            <Route path={":id"} element={<Group />} />
            <Route path={"list"} element={<ListGroup />} />
            <Route path={"edit/:id"} element={<CreateGroup />} />
            <Route path={"create"} element={<CreateGroup />} />
          </Route>
          <Route path={"groups"} element={<GroupSelect />} />
          <Route path="user">
            <Route path={"list"} element={<Users />} />
            <Route path={":id"} element={<UserProfile />} />
            <Route path={"create"} element={<CreateUser />} />
            <Route path={"edit/:id"} element={<CreateUser />} />
          </Route>
          <Route path={"profile"} element={<Profile />} />
          <Route path="leave">
            <Route path={"type"}>
              <Route path={"list"} element={<LeaveTypes />} />
              <Route path={"edit/:id"} element={<CreateLeaveType />} />
              <Route path={"create"} element={<CreateLeaveType />} />
            </Route>
            <Route path={":id"} element={<Leave />} />
            <Route path={"edit/:id"} element={<EditLeave />} />
            <Route path={"request"} element={<LeaveRequest />} />
            <Route path={"response"}>
              <Route path={"pending"} element={<LeaveResponse />} />
              <Route path={"history"} element={<HistoryResponse />} />
            </Route>
            <Route path={"admin"} element={<AllLeaveResponse />} />
          </Route>
          <Route path="holiday">
            <Route path={"list"} element={<PublicHolidays />} />
            <Route path={"edit/:id"} element={<CreatePublicHolidays />} />
            <Route path={"create"} element={<CreatePublicHolidays />} />
          </Route>
          <Route path={"config"} element={<ConfigPage />} />
          <Route path="*" element={<FourOFour />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
