import Select from "../../Utils/Select";
import {useState} from "react";
import ApproveModal from "./ApproveModal";
import Card from "../../Utils/Card";
import Button, {ConfirmButton} from "../../Utils/Button";
import {cancelLeave} from "../../Services/leave";
import useIsMobile from "../../Hooks/isMobile";
import {Link} from "react-router-dom";
import useMe, {useMyGroupsManage} from "../../Hooks/useMe";
import { TextArea } from "Utils/InputText";

function Actions(props) {
  const [showModal, setShowModal] = useState(false);
  const [accept, setAccept] = useState(null);
  const {leave, config, manager} = props;
  const me = useMe();
  const {isManager} = useMyGroupsManage(me);
  const [comment, setComment] = useState();


  const isSuperior = isManager(leave.requester) === 2 || (isManager(leave.requester) && manager === undefined) || me.roles.includes("ROLE_ADMIN");

  function cancel() {
    cancelLeave(leave.id, comment).then(() => {
      config.update();
    });
  }

  return (
    <>
      <div className={"text-gray-600 text-xs md:hidden"}>
        {new Date(leave.dateRequest).toLocaleDateString("fr-FR")}
      </div>
      <div className={"flex flex-col gap-x-4 gap-y-1 md:w-full"}>
        {(config.toApprove || (config.auto && leave.status === 'pending')) && isSuperior && me.id !== leave.requester.id && <>
          <div className={"md:w-full max-w-52 w-44"}>
            <Select
              onChange={(event) => {
                if (event.target.value !== null) {
                  setShowModal(true);
                  setAccept(event.target.value);
                }
              }}
              value={accept}
              options={[
                {label: "En attente", value: null},
                {label: "Accepter", value: true},
                {label: "Refuser", value: false},
              ]}/>
            <ApproveModal show={showModal} setShow={setShowModal} leave={leave} approve={accept} setApprove={setAccept}
                          update={config.update}/>
          </div>
        </>}
        {me.roles.includes("ROLE_ADMIN") && leave.status === "approved" && 
        <Link to={"/leave/edit/" + leave.id}>
          <Button variant="outline" className={"md:w-full max-w-52 w-44"}>Modifier</Button>
        </Link>}
        {((config.cancel && leave.requester.id === me.id && leave.status === "pending")
            || ((config.auto || config.history) && leave.status === "approved" && isSuperior))
          && !config.toApprove &&
          <ConfirmButton
            className={"md:w-full max-w-52 w-44"}
            color={"red"}
            title={"Êtes vous sûr de vouloir annuler ce congé ?"}
            message={
              <div className="flex flex-col gap-4">
                <p className={"text-gray-500"}>Vous ne pourrez pas revenir en arrière.</p>
                <label htmlFor={"motif"} className={"text-black font-medium text-sm"}>Motif d'annulation:</label>
                <TextArea id={"motif"} placeholder={"Commentaire"} className={"h-24"}
                          onChange={(e) => setComment(e.target.value)}
                />
              </div>
            }
            onClick={cancel}
            value={accept}>Annuler</ConfirmButton>
        }
      </div>
    </>
  );
}

export default function LeaveRow(props) {
  const {leave, config, manager} = props;
  const isMobile = useIsMobile();
  const statusColors = {
    "approved": "bg-green-600",
    "refused": "text-white bg-red-600",
    "ignored": "text-white bg-orange-600",
    "canceled": "text-white bg-gray-600",
    "pending": "bg-indigo-600"
  }
  const textStatus = (config.history || config.auto)
    ? ("text-white rounded -mt-1 -ml-1 px-2 py-1 " + statusColors[leave.status]) : "text-indigo-600";

  return (
    <Card className={"text-sm flex flex-col items-end py-4 px-4 gap-2"}>
      <div className={"flex justify-start md:flex-row flex-col w-full"}>
        <div
          className={"font-medium text-gray-900 flex md:flex-col md:justify-start gap-3 items-center justify-between md:items-start md:w-[20%] pr-1 pb-2"}>
          <Link to={"/user/" + leave.requester.id} className={"text-lg underline " + textStatus}>{leave.requester.fname} {leave.requester.lname}</Link>
          <p><span className={"hidden lg:inline"}>Service:</span> {
            leave.requester.inGroup ? leave.requester.inGroup.name : "Aucun"
          }</p>
        </div>
        <div className={"font-medium text-gray-900 md:w-[22%]"}>
          {leave.duration} jour(s)
          <div className={"flex justify-between md:flex-col"}>
            <span className={"pr-2"}>
              Du {leave.halfDayStart ? "midi" : "matin"}&nbsp;
              {new Date(leave.start).toLocaleDateString("fr-FR")}&nbsp;
            </span>
            <span>
              Au {leave.halfDayEnd ? "midi" : "soir"}&nbsp;
              {new Date(leave.end).toLocaleDateString("fr-FR")}
            </span>
          </div>
        </div>
        <div className={"text-gray-900 flex flex-row justify-between md:flex-col md:justify-start gap-1 md:w-[18%]"}>
          Type: {leave.type.name}
          {leave.reviewedBy &&
            <div className={"text-gray-800 text-sm flex gap-1"}>
              Répondu par
              <Link to={"/user/" + leave.reviewedBy.id} className={"underline whitespace-nowrap font-bold"}>{leave.reviewedBy.fname} {leave.reviewedBy.lname}</Link>
            </div>
          }
          <span
            className={"hidden md:block"}>Demandé le {new Date(leave.dateRequest).toLocaleDateString("fr-FR")}
          </span>
        </div>
        <div className={"text-xs font-medium text-gray-600 md:w-[20%] pt-2 md:pt-0"}>
          <div className={"font-medium text-gray-900 text-sm"}>
            Commentaire:
          </div>
          {leave.comment}
        </div>
        {leave.response &&
          <div className={"text-xs text-gray-600 lg:w-44 md:pt-0 pt-2 md:w-[20%]"}>
            <div className={"font-medium text-gray-900 text-sm"}>
              {leave.status === "refused" ? "Motif de refus:" : "Motif d'annulation"}
            </div>
            {leave.response}
          </div>
        }
        {!leave.response &&
          <div className={"md:w-[20%] flex justify-center items-center"}>
            {!isMobile && <Actions leave={leave} config={config} manager={manager[leave.requester.id]}/>}
          </div>
        }
      </div>
      <div className={"flex justify-between md:justify-end w-full items-end"}>
        {isMobile && <Actions leave={leave} config={config} manager={manager[leave.requester.id]} />}
      </div>
    </Card>
  );
}