import SideBar from "../Layouts/SideBar";
import Card from "../Utils/Card";
import Icon from "../Utils/Icon";
import {Link} from "react-router-dom";


export default function ConfigPage() {
  return (
    <SideBar>
      <div className={"flex w-full h-full flex-col items-center justify-start gap-8 md:p-8 p-4 text-gray-700"}>
        <div className={"flex w-full gap-4 justify-between pl-18 md:pl-0"}>
          <Card className={"transi px-5 py-3 px-3flex items-center"}>
            <h1 className={"lg:text-2xl text-xl font-bold text-gray-700"}>Page de Gestion</h1>
          </Card>
        </div>
        <div className={"flex flex-col gap-4 mt-4 w-full"}>
          <Link to={"/user/list"}>
            <Card className={"flex gap-4 items-center p-4 px-8 cursor-pointer h-full"}>
              <Icon name={"person"} className={"text-5xl text-gray-500"}/>
              <h1 className={"text-xl font-bold text-gray-700"}>Utilisateurs</h1>
            </Card>
          </Link>
          <Link to={"/group/list"}>
            <Card className={"flex gap-4 items-center p-4 px-8 cursor-pointer h-full"}>
              <Icon name={"group"} className={"text-5xl text-gray-500"}/>
              <h1 className={"text-xl font-bold text-gray-700"}>Groupes</h1>
            </Card>
          </Link>
          <Link to={"/leave/admin"}>
            <Card className={"flex gap-4 items-center p-4 px-8 cursor-pointer h-full"}>
              <Icon name={"beach_access"} className={"text-5xl text-gray-500"}/>
              <h1 className={"text-xl font-bold text-gray-700"}>Gestion des congés</h1>
            </Card>
          </Link>
          <Link to={"/holiday/list"}>
            <Card className={"flex gap-4 items-center p-4 px-8 cursor-pointer h-full"}>
              <Icon name={"event"} className={"text-5xl text-gray-500"}/>
              <h1 className={"text-xl font-bold text-gray-700"}>Gestion des jours fériés</h1>
            </Card>
          </Link>
          <Link to={"/leave/type/list"}>
            <Card className={"flex gap-4 items-center p-4 px-8 cursor-pointer h-full"}>
              <Icon name={"event_note"} className={"text-5xl text-gray-500"}/>
              <h1 className={"text-xl font-bold text-gray-700"}>Types de congés</h1>
            </Card>
          </Link>
        </div>
      </div>
    </SideBar>
  )

}