import Modal from "../../Utils/Modal";
import Card from "../../Utils/Card";
import Button from "../../Utils/Button";
import {TextArea} from "../../Utils/InputText";
import {useState} from "react";
import {respondLeave} from "../../Services/leave";
import WarningDelay from "../WarningDelay";


export default function ApproveModal(props) {
  const {show, setShow, leave, approve, setApprove, update} = props;
  const [comment, setComment] = useState("");
  const [error, setError] = useState("");

  function confirm() {
    respondLeave(leave.id, approve, comment).then((json) => {
      if (!json || json.error) {
        setError("Erreur lors de la confirmation");
      } else if (json.message) {
        setError(json.message);
      } else {
        setShow(false);
        setApprove(null);
        update();
      }
    });
  }

  return (
    <Modal show={show} setShow={setShow}>
      <Card className={"p-10 flex flex-col gap-6"}>
        <h1 className="text-xl font-bold">Êtes vous sur de vouloir <b className={"text-indigo-500"}>{approve ? "accepter" : "refuser"}</b> cette
          demande de congé ?</h1>
        <div className={"flex flex-col gap-4 px-3 text-gray-800"}>
          <div className="flex gap-5 justify-between">
            <p className={"text-md font-medium w-1/2"}>
              De: <b className={"text-indigo-500"}>{leave.requester.fname} {leave.requester.lname}</b>
            </p>
            <p className={"text-sm font-medium w-1/2"}>
              Type: {leave.type.name}
            </p>
          </div>
          <div className="flex gap-5 justify-between">
            <p className={"text-md font-medium w-1/2"}>
              Du {leave.halfDayStart ? "midi" : "matin"}&nbsp;
              {new Date(leave.start).toLocaleDateString("fr-FR")}
            </p>
            <p className={"text-sm font-medium w-1/2"}>
              Au {leave.halfDayEnd ? "midi" : "soir"}&nbsp;
              {new Date(leave.end).toLocaleDateString("fr-FR")}
            </p>
          </div>
          <p className={"text-sm font-medium"}>
            Pour un total de {leave.duration} jour(s)
          </p>
          <div>
            <p className={"text-sm font-medium pb-1"}>
              Commentaire:
            </p>
            <p className={"pl-2 text-xs font-medium text-gray-600"}>
              {leave.comment}
            </p>
          </div>
        </div>
        {!approve &&
        <div className={"flex flex-col gap-1"}>
          <label htmlFor={"motif"} className={"text-black font-medium text-sm"}>Motif de refus:</label>
          {error && <p className={"text-red-500"}>{error}</p>}
          <TextArea id={"motif"} placeholder={"Commentaire"} className={"h-24"}
                    onChange={(e) => setComment(e.target.value)}
          />
        </div>}
        <WarningDelay duration={leave.duration} start={leave.start}/>
        <p className="text-gray-500">Vous ne pourrez pas revenir en arrière.</p>
        <div className="flex justify-end text-lg gap-5">
          <Button onClick={() => {
            setShow(false)
            setApprove(null)
          }}
                  color={"red"} variant={"outline"}
                  className={"min-w-[25%] hover:bg-red-500"}>Annuler</Button>
          {/* NECESSARY FOR TAILWIND bg-green-500 */}
          <Button onClick={confirm} color="green" className={"min-w-[25%] hover:bg-green-600"}>Confirmer</Button>
        </div>
      </Card>
    </Modal>
  );
}